<div class="application-details-lender-task-header m-5">
    <div class="flex flex-row items-start mb-1 borrower-name-status-container">
        <div class="text-lg font-semibold mr-2">{{borrowerName}}</div>
        <lendio-deal-stage-display
            [stage]="deal?.stage"
            [status]="deal?.status"
        >
        </lendio-deal-stage-display>
    </div>
    <div class="deal-details-container text-lendio-warm-gray-400 font-semibold">
        <div>{{ deal?.loan_product.name }}</div>
        @if (deal?.assignments) {
            <div>Assigned to: {{ assignments }}</div>
        }
    </div>
</div>