import { ApplicationDetailsState } from '@app/app/store/application-details/application-details.state';
import {Action, Selector, State, StateContext, Store} from "@ngxs/store";
import {
  AddNewLaserProProgress,
  PushToLaserPro,
  SubscribeToLaserPro,
  UnsubscribeFromLaserPro
} from "@app/app/store/laser-pro/laser-pro.actions";
import { append, patch } from "@ngxs/store/operators";
import { Injectable } from "@angular/core";
import {LaserProService} from "@app/app/services/laser-pro.service";
import {LendioSnackbarService} from "@app/app/services/lendio-snackbar.service";
import {Router} from "@angular/router";
import {PusherService} from "@app/app/services/pusher.service";
import {AuthState} from "@app/app/store/auth/auth.state";
import {IntegrationActivity} from "@app/app/interfaces/integration.model";
import {UpdateDealStatus} from "@app/app/store/application-details/application-details.actions";

export class LaserProStateModel {
  laserProProgress: Partial<IntegrationActivity>[];
  laserProProcessing: boolean;
  laserProFailed: boolean;
  laserProSucceeded: boolean;
}

@State<Partial<LaserProStateModel>>({
  name: 'laserPro',
})

@Injectable()
export class LaserProState {

  constructor(
    private router: Router,
    private store: Store,
    private pusherService: PusherService,
    private laserProService: LaserProService,
    private _snackbarService: LendioSnackbarService
  ) {}

  /**
   * track progress of laser pro via integration activities
   * @param state
   * @returns array
   */
  @Selector()
  static laserProProgress(state: LaserProStateModel) {
    return state.laserProProgress;
  }

  /**
   * Determine if laser pro push is processing
   * @param state
   * @returns boolean
   */
  @Selector()
  static laserProProcessing(state: LaserProStateModel) {
    return state.laserProProcessing;
  }

  /**
   * Determine if laser pro push failed
   * @param state
   * @returns boolean
   */
  @Selector()
  static laserProFailed(state: LaserProStateModel) {
    return state.laserProFailed;
  }

  /**
   * Determine if laser pro push succeeded
   * @param state
   * @returns boolean
   */
  @Selector()
  static laserProSucceeded(state: LaserProStateModel) {
    return state.laserProSucceeded;
  }

  /**
   *
   * @param ctx
   * @param dealId
   * @param businessName
   */
  @Action(PushToLaserPro)
  pushToLaserPro(ctx: StateContext<LaserProStateModel>, {dealId, businessName}: PushToLaserPro) {
    ctx.setState(
      patch<LaserProStateModel>({
        laserProProcessing: true,
        laserProFailed: false,
      })
    );

    return this.laserProService.pushToLaserPro(dealId)
      .subscribe({
        error: (error) => {
          ctx.patchState({
            laserProProcessing: false,
            laserProFailed: true,
          });

          const currentState = ctx.getState();
          if(currentState?.laserProProgress[0]?.errored) {
            this._snackbarService.open({
              message: `LaserPro processing failed for ${businessName}. ${currentState?.laserProProgress[0]?.errorMessage}`,
              canDismiss: true,
              duration: 5000,
              prefix: {
                type: 'mat-icon',
                backgroundColor: '#DA5044',
                matIconLabel: 'error',
                matIconColor: 'white'
              },
              action: {
                label: 'View',
                cb: () => {
                  this.router.navigate([`/administration/integrations/2`]);
                }
              }
            });
          }
        },
      });
  }

  /**
   * subscribe to laser pro updates as integration activities
   * @param dealId
   */
  @Action(SubscribeToLaserPro)
  subscribeToLaserPro({ }: StateContext<LaserProStateModel>, { dealId }: SubscribeToLaserPro) {
    if(!dealId) {
      return;
    }

    // Make sure this is the active deal
    const activeDealId = this.getActiveDealId();
    if (activeDealId !== dealId) {
      return;
    }

    this.pusherSubscribeToLaserPro(dealId);
  }

  /**
   * unsubscribe to laser pro updates as integration activities
   * @param dealId
   */
  @Action(UnsubscribeFromLaserPro)
  unsubscribeFromLaserPro({ }: StateContext<LaserProStateModel>, { dealId }: SubscribeToLaserPro) {
    if(!dealId) {
      return;
    }

    this.pusherUnsubscribeFromLaserPro(dealId);
  }

  /**
   * @param ctx
   * @param integrationActivity
   */
  @Action(AddNewLaserProProgress)
  addNewLaserProProgress(ctx: StateContext<LaserProStateModel>, { integrationActivity }: AddNewLaserProProgress) {
    ctx.setState(
      patch<LaserProStateModel>({
        laserProProgress: append([integrationActivity]),
        laserProProcessing: true,
      })
    )

    const businessName = this.store.selectSnapshot(ApplicationDetailsState.applicationDetails)?.deal?.borrower?.name
                          + ': ' + this.store.selectSnapshot(ApplicationDetailsState.applicationDetails)?.deal?.loan_product?.name
                        || 'Unknown';

    if (integrationActivity.errored) {
      ctx.setState(
        patch<LaserProStateModel>({
          laserProProcessing: false,
          laserProFailed: true,
          laserProSucceeded: false,
        })
      )

      if (integrationActivity.action === 'postDealToLaserPro') { // laser pro specific error
        const { customerIntegrationId } = integrationActivity
        this._snackbarService.open({
          message: `${businessName} submission to LaserPro failed. ${integrationActivity.errorMessage}`,
          canDismiss: true,
          duration: 5000,
          prefix: {
            type: 'mat-icon',
            backgroundColor: 'lendio-red-400',
            matIconLabel: 'error',
            matIconColor: 'white'
          },
          action: {
            label: 'View',
            cb: () => {
              this.router.navigate([`/administration/integrations/${customerIntegrationId}`]);
            }
          }
        });
      } else { // lendio error while validating data for submission to laser pro
        this._snackbarService.open({
          message: `LaserPro processing failed for ${businessName} ${integrationActivity.errorMessage}`,
          canDismiss: true,
          duration: 5000,
          prefix: {
            type: 'mat-icon',
            backgroundColor: '#DA5044',
            matIconLabel: 'error',
            matIconColor: 'white'
          },
          action: {
            label: 'View',
            cb: () => {
              this.router.navigate([`/administration/integrations/2`]);
            }
          }
        });
      }
    } else {
        ctx.setState(
          patch<LaserProStateModel>({
            laserProProcessing: false,
            laserProSucceeded: true,
          })
        )

        this.store.dispatch(
          new UpdateDealStatus(
            integrationActivity.dealId,
            {
              value: 'funding',
              display: 'funding',
              stage: 'closing',
              hidden: false,
              disabledReason: null,
            },
            '',
            ''
          )
        );
        this._snackbarService.open({
          message: `${businessName} successfully submitted to LaserPro`,
          canDismiss: false,
          duration: 5000,
          prefix: {
            type: 'mat-icon',
            backgroundColor: 'green',
            matIconLabel: 'done',
            matIconColor: 'white'
          }
        });
    }
  }

  /**
   * @param dealId
   */
  pusherSubscribeToLaserPro(dealId: number) {
    this.pusherService.subscribe({
      name: `laser-pro-update-${this.store.selectSnapshot(AuthState.user)?.institution?.id}-${dealId}`,
      auth: false,
      handler: (event: any, integrationActivity: IntegrationActivity) => {
          this.handlePusher(event, integrationActivity);
          return;
      }
    });
  }

  /**
   * add new integration activity to array of laser pro progress
   * @param event
   * @param integrationActivity
   * @returns void
   */
  handlePusher(event: any, integrationActivity: IntegrationActivity): void {
    this.store.dispatch(new AddNewLaserProProgress(integrationActivity));
  }

  /**
   * @param dealId
   */
  pusherUnsubscribeFromLaserPro(dealId: number) {
    this.pusherService.unsubscribe({
      name: `laser-pro-update-${this.store.selectSnapshot(AuthState.user)?.institution?.id}-${dealId}`,
      auth: false,
      handler: () => {
        return;
      }
    })
  }

  /**
   * @returns number
   */
  private getActiveDealId(): number {
    const route = this.router.url;
    const parts = route.split('/');
    return parseInt(parts[2]);
  }
}
