import { ValueFormatterParams } from 'ag-grid-community';

export const camelCaseFormatter = ({ value }: ValueFormatterParams | { value: string, data?: any }): string => {
  if (!value) {
    return '';
  }
  const withSpaces = value.replace(/([a-z])([A-Z])/g, '$1 $2');
  return withSpaces.charAt(0).toUpperCase() + withSpaces.slice(1).toLowerCase();
}

export const capitalizeFormatter = ({ value }: ValueFormatterParams | { value: string, data?: any}, defaultValue: string = ''): string => {
  if (!value) {
    return defaultValue;
  }

  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
}
