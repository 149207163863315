import { Component } from '@angular/core';
import { IToolPanelParams } from 'ag-grid-community';
import { IToolPanelAngularComp } from 'ag-grid-angular';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-funnel-columns-tool-panel',
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
    FormsModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule
  ],
  templateUrl: './columns-tool-panel.component.html',
  styleUrl: './columns-tool-panel.component.scss'
})
export class ColumnsToolPanelComponent implements IToolPanelAngularComp {
  params: IToolPanelParams;
  columns: any[] = [];
  filteredColumns: any[] = [];
  searchQuery = '';

  agInit(params: IToolPanelParams): void {
    this.params = params;
    this.params.api.addEventListener('toolPanelVisibleChanged', this.updateColumns.bind(this));
    this.params.api.addEventListener('columnVisible', this.onColumnVisibilityChange.bind(this));
    this.columns = this.params.api.getAllGridColumns();
    this.filteredColumns = this.columns;
  }

  updateColumns(): void {
    this.columns = this.params.api.getAllGridColumns();
    this.filteredColumns = this.columns;
  }

  closeSidebar(): void {
    this.params.api.closeToolPanel();
  }

  onColumnVisibilityChange(): void {
    this.params.api.setColumnsVisible(
      this.filteredColumns.filter(col => col.visible),
      true
    );
  }

  onColumnFilterInput(): void {
    const filterText = this.searchQuery?.toLowerCase();
    this.filteredColumns = this.columns.filter(col => {
        return col.colDef.headerName.toLowerCase().includes(filterText);
      }
    );
  }

  refresh(): void { }

}
