import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { FileDragNDropDirective } from './file-drag-n-drop.directive';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FileUploadDialogComponent } from './file-upload-dialog/file-upload-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { LoadersModule } from '../loaders/loaders.module';
import { AdvancedFileUploadDialogComponent } from './advanced-file-upload-dialog/advanced-file-upload-dialog.component';
import { AdvancedFileUploadComponent } from './advanced-file-upload/advanced-file-upload.component';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';

/**
 * Since this is a generic feature (uploading files), it's now a module
 * that can be imported into other modules.
 *
 * NOTE: this was moved from embedded to be more visible/reusable, but may
 * need additional work to integrate with other parts of BP.
 */
@NgModule( {
  declarations: [
    AdvancedFileUploadComponent,
    AdvancedFileUploadDialogComponent,
    FileUploadComponent,
    FileDragNDropDirective,
    FileUploadDialogComponent,
  ],
  exports: [
    AdvancedFileUploadComponent,
    AdvancedFileUploadDialogComponent,
    FileUploadComponent,
    FileDragNDropDirective,
    FileUploadDialogComponent,
  ], imports: [
    CommonModule,
    FormsModule,
    LoadersModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatDialogModule,
    MatDividerModule,
    NgxMaskDirective,
    NgxMaskPipe,
  ]
} )
export class FileUploadModule {
}
