import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@app/environments/environment';
import { LendioResponse } from '@app/app/interfaces/lendio-response';
import { GridState } from 'ag-grid-community';

const agGridStateUrl = `${environment.apiUrl}/partner-portal/ag-grid-states`;

export interface AgGridStateResponse {
  data: { gridState: GridState | null };
}

@Injectable({
  providedIn: 'root'
})
export class FunnelGridStateService {
  constructor(private _http: HttpClient) { }

  getFunnelState(tableName = 'funnel'): Observable<AgGridStateResponse> {
    return this._http.get<AgGridStateResponse>(`${agGridStateUrl}/${tableName}`, {});
  }

  postFunnelState(funnelState, tableName = 'funnel'): Observable<LendioResponse> {
    return this._http.patch<LendioResponse>(`${agGridStateUrl}/${tableName}`, { state: funnelState });
  }
}
