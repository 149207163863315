import { GridState } from 'ag-grid-community';

export class GetFunnelGridState {
  static readonly type = '[FunnelGridState] GetFunnelGridState';
  constructor() { }
}

export class SaveFunnelGridState {
  static readonly type = '[FunnelGridState], SaveFunnelGridState';
  constructor(public gridState: GridState) { }
}

export class ClearFunnelGridState {
  static readonly type = '[FunnelGridState] ClearFunnelGridState';
  constructor() { }
}
