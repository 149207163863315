import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { GridStateModel } from '@app/app/interfaces/grid-state.model';
import { Injectable } from '@angular/core';
import {
  ClearFunnelGridState,
  GetFunnelGridState,
  SaveFunnelGridState
} from '@app/app/store/funnel-deals-grid/funnel-deals-grid.actions';
import { FunnelGridStateService } from '@app/app/services/funnel-grid-state.services';
import { AuthState } from '../auth/auth.state';
import { catchError, tap, throwError } from 'rxjs';

@State<GridStateModel>({
  name: 'funnelGridState',
  defaults: {
    gridState: null,
    gridStateLoaded: false,
    gridStateCached: false
  }
})
@Injectable()
export class FunnelGridState {

  @Selector() static funnelGridState({ gridState }: GridStateModel) {
    return { gridState };
  }

  @Selector() static funnelGridStateLoaded({ gridStateLoaded }: GridStateModel) {
    return gridStateLoaded;
  }

  constructor(
    private funnelStateService: FunnelGridStateService,
    private store: Store
) {}

  @Action(GetFunnelGridState)
  getFunnelGridState({ patchState }: StateContext<GridStateModel>, {}: GetFunnelGridState) {
    const isSbaSpecialist = this.store.selectSnapshot(AuthState.isSbaSpecialist);
    const agGridTable = isSbaSpecialist ? 'sba-funnel' : 'funnel';
    return this.funnelStateService.getFunnelState(agGridTable).pipe(
      catchError((error) => {
        patchState({ gridStateLoaded: true });
        return throwError(error);
      }),
      tap({
        next: (response) => {
          const { data } = response;
          const gridState = data.gridState;
          patchState({
            gridState,
            gridStateLoaded: true
          })
        },
      }));
  }

  @Action(SaveFunnelGridState)
  saveFunnelGridState({ patchState }: StateContext<GridStateModel>, { gridState }: SaveFunnelGridState) {
    const isSbaSpecialist = this.store.selectSnapshot(AuthState.isSbaSpecialist);
    const agGridTable = isSbaSpecialist ? 'sba-funnel' : 'funnel';
    return this.funnelStateService.postFunnelState(gridState, agGridTable).pipe(
      catchError((error) => {
        return throwError(error);
      }),
      tap({
        next: (response) => {
          patchState({
            gridState,
            gridStateLoaded: true
          })
        },
      }));
  }

  @Action(ClearFunnelGridState)
  clearFunnelGridState({ patchState }: StateContext<GridStateModel>, {}: ClearFunnelGridState) {
    return patchState({
      gridState: undefined,
      gridStateCached: false,
    })
  }
}
