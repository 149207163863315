import { Component, Output, EventEmitter, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UploadFileType } from '@app/app/interfaces/upload-file-type.model';
import { FileWithCategory } from '../advanced-file-upload/advanced-file-upload.component';

/**
 * FileUploadDialogComponent wraps the FileUploadComponent when you want to
 * use it within a modal.
 */
@Component({
  selector: 'advanced-file-upload-dialog',
  templateUrl: './advanced-file-upload-dialog.component.html',
  styleUrls: ['./advanced-file-upload-dialog.component.scss']
})
export class AdvancedFileUploadDialogComponent {
  @Output() onClose = new EventEmitter<void>();
  @Output() fileInput = new EventEmitter<File>();
  @Output() fileListInput = new EventEmitter<FileWithCategory[]>();

  fileList: FileWithCategory[];
  formIsValid = false;
  isUploading = false;
  dialogTitle = this.data.title;

  /**
   * Inject data from the client via `data`.
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      // Dialog data.
      title?: string,
      closeLabel?: string,
      // Pass through to file upload component.
      allowedFileTypes?: UploadFileType[],
      fileSizeLimit?: number,
      fileCount?: number,
      label?: string
      fileSelectText?: string,
      buttonText?: string,
    },
    public selfDialogRef: MatDialogRef<AdvancedFileUploadDialogComponent>,
  ) {}

  close($event: Event) {
    $event.stopPropagation();
    this.selfDialogRef.close();
  }

  uploadDocumuents() {
    this.isUploading = true;
    this.dialogTitle = 'Uploading...';
    this.fileListInput.emit(this.fileList);
  }
}
