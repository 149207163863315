import { Component, AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { forkJoin, Observable, Subject } from 'rxjs';
import { CommonModule } from '@angular/common'; 
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LendioSnackbarService } from '@app/app/services/lendio-snackbar.service';
import { MatDialog } from '@angular/material/dialog';
import JSZip from 'jszip';
import { HttpClient } from '@angular/common/http';
import { Document } from '@app/app/interfaces/document.model';
import { environment } from '@app/environments/environment';
import { ConfirmDialogComponent } from '@app/app/components/dialogs/confirm-dialog/confirm-dialog.component';
import { DocumentsActions } from '@app/app/store/documents/documents.actions';
import { AdvancedDocumentEditDialogComponent } from '@app/app/components/documents/advanced-document-edit-dialog/advanced-document-edit-dialog.component';
import { Business, BusinessAccessLevel } from '@app/app/interfaces/business.model';
import { DocumentRejectionDialogComponent } from '@app/app/components/documents/document-rejection-dialog/document-rejection-dialog.component';

interface DocumentCellParams extends ICellRendererParams {
    dealId?: number;
    borrowerId?: number;
    currentUserInstitutionId?: number;
    business?: Business;
    canCrudMpDealDocs?: boolean;
    loanProductCategory?: string;
}
@Component({
    selector: 'grid-dynamic-document-cell',
    standalone: true,
    imports: [
        MatIconModule,
        CommonModule,
        MatTooltipModule,
    ],
    templateUrl: './dynamic-document-cell.component.html'
})
export class DynamicDocumentCellComponent implements ICellRendererAngularComp, AfterViewInit, OnDestroy, OnInit {
    public dealId?: number;
    public borrowerId?: number;
    public currentUserInstitutionId?: number;
    public business?: Business;
    public canCrudMpDealDocs?: boolean;
    public loanProductCategory?: string;
    public accepted: boolean;
    public rejected: boolean;
    public scanIsClean: boolean;
    public mouseOver: boolean;
    public canEditAndDelete: boolean;
    public params: DocumentCellParams;
    private rowElement!: HTMLElement;
    destroyed$ = new Subject<boolean>();
    mouseOverLock: boolean;

    constructor(
        private _store: Store,
        private dialog: MatDialog,
        private _snackbarService: LendioSnackbarService,
        private http: HttpClient,
    ) {
    }

    agInit(params: ICellRendererParams): void {
        this.params = params;
        this.mouseOver = false;
        this.rejected = this.params.data.status?.rejected;
        this.accepted = this.params.data.status?.accepted;
        this.scanIsClean = this.params.data.scanStatus === 'CLEAN';
        this.canEditAndDelete = this.hasReadWriteAccess();
    }
    
    ngAfterViewInit() {
        // Get the row element after the view is initialized
        this.rowElement = this.params.eGridCell.parentElement as HTMLElement;
    
        // Add event listeners to the row element
        this.rowElement.addEventListener('mouseover', this.onRowMouseOver.bind(this));
        this.rowElement.addEventListener('mouseout', this.onRowMouseOut.bind(this));
    }

    ngOnInit() {
        this.dealId = this.params.dealId;
        this.borrowerId = this.params.borrowerId;
        this.business = this.params.business;
        this.loanProductCategory = this.params.loanProductCategory;
        this.canCrudMpDealDocs = this.params.canCrudMpDealDocs;
        this.currentUserInstitutionId = this.params.currentUserInstitutionId;
        this.canEditAndDelete = this.hasReadWriteAccess();
    }
    /**
     * Ng lifecycle
     * @return void
     */
    ngOnDestroy(): void {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }

    value(): string {
        return this.params.value
    }

    refresh(params: ICellRendererParams) {
        return false;
    }

    onRowMouseOver() {
        this.mouseOver = true;
    }

    onRowMouseOut(event) {
        if (!this.rowElement.contains(event.relatedTarget)) {
            this.mouseOver = false;
        }
    }

    handleAccept(event) {
        event.stopPropagation();
        const { data } = this.params;

        if (this.dealId) {
            if (this.accepted) {
                this._store.dispatch(new DocumentsActions.ClearDealDocumentStatus(this.dealId, data.id));
            } else {
                this._store.dispatch(new DocumentsActions.AcceptDealDocument(this.dealId, data.id));
            }
        }

        this.rejected = false;
        this.accepted = !this.accepted;
    }

    handleReject(event) {
        event.stopPropagation();
        const { data } = this.params;

        if (this.dealId) {
            this.openRejectionDialog(data);
        }
    }

    handleDownload(event) {
        event?.stopPropagation();
        const { data } = this.params;
        this.downloadDoc(data);
    }

    downloadDoc(doc: Document): void {
        const zip = new JSZip();
        const downloadObservables: Observable<Blob>[] = [];
        downloadObservables.push(this.http.get(this.documentUrl(doc.id), { responseType: 'blob' }));

        forkJoin(downloadObservables).subscribe(responses => {
            responses.forEach((response, index) => {
                zip.file(`document_${doc.filename}`, response, { binary: true });
            });
            zip.generateAsync({ type: 'blob' }).then(content => {
                const fileURL = URL.createObjectURL(content);
                const link = document.createElement('a');
                link.href = fileURL;
                link.download = `document_${doc.filename}.zip`;
                link.style.display = 'none';
                document.body.appendChild(link);
                link.click();
                URL.revokeObjectURL(fileURL);
                document.body.removeChild(link);
            });
        });
    }

    handleEdit(event) {
        event?.stopPropagation();
        const { data } = this.params;
        this.openEditDialog(data);
    }

    openEditDialog(document: Document): void {
        const dialogRef = this.dialog.open(AdvancedDocumentEditDialogComponent, {
            width: '372px',
            disableClose: true,
            data: {
                document,
                dealId: this.dealId
            },
        });
    
        dialogRef.componentInstance.onSave.subscribe({
            next: () => {
                const message = "Changes have been saved.";
                this.accepted = false;
                this.rejected = !this.rejected;
                this._snackbarService.open({
                    message,
                    canDismiss: true,
                    duration: 3000
                });
            }
        });
    }

    openRejectionDialog(document: Document): void {
        const dialogRef = this.dialog.open(DocumentRejectionDialogComponent, {
            width: '468px',
            disableClose: false,
            data: {
                dealId: this.dealId,
                document
            },
        });
    
        dialogRef.componentInstance.onSave.subscribe({
            next: () => {
                const message = `File rejected for ${this.business?.name}: ${this.loanProductCategory}.`;
                this._snackbarService.open({
                    message,
                    canDismiss: true,
                    duration: 3000
                });
            }
        });
    }

    handleDelete(event) {
        event?.stopPropagation();
        const { data } = this.params;
        this.deleteDocument(data);
    }

    deleteDocument(document: Document) {
        const documentIds: Array<number> = [];
        documentIds.push(document.id);
    
        const message = ["document", "Document", "this", "was"];
    
        let confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: {
                title: "Delete " + message[0] + "?",
                description: "Are you sure you want to delete " + message[2] + " " + message[0] + "?",
                cancelLabel: "Cancel",
                confirmLabel: "Delete",
                confirmStyles: "background-color: red;",
                width: "352px"
            }
        });
    
        confirmDialogRef.componentInstance.onCancel.subscribe(() => {
            confirmDialogRef.close();
        });
    
        // Proceed with deleting the docs.
        confirmDialogRef.componentInstance.onConfirm.subscribe(() => {
            documentIds.forEach((documentId) => {
            // Hit deal doc or borrower doc delete route
                const deleteAction = this.dealId !== undefined
                    ? new DocumentsActions.DeleteDealDocument(this.dealId, documentId)
                    : new DocumentsActions.Delete(documentId);
        
                this._store.dispatch(deleteAction).subscribe({
                    error: () => {
                        confirmDialogRef.close();
                    },
                    complete: () => {
                        confirmDialogRef.close();
                        this._snackbarService.open({
                            message: message[1] + " " + message[3] + " deleted.",
                            canDismiss: true,
                            duration: 3000
                        });
                    }
                });
            });
        });
    }

    cellClickHandler(event) {
        event.stopPropagation();
    }

    hasReadWriteAccess(): boolean {
        return (
            this.business?.id == this.borrowerId &&
            this.business?.accessLevel === BusinessAccessLevel.Modify
        ) || (
            !!this.canCrudMpDealDocs &&
            !!this.dealId &&
            this.currentUserInstitutionId == this.params.data.lenderId
        );
    }

    private documentUrl(docId: number): string {
        if (this.dealId) {
            return `${environment.apiUrl}/l/v2/internal/document/${docId}/stream?dealId=${this.dealId}`;
        } else {
            return `${environment.apiUrl}/document/${docId}?stream=1`;
        }
    }
}
