import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ApplicationDetails } from '@app/app/interfaces/application-details.model';
import {BehaviorSubject, combineLatest, map, Observable, Subject, take} from 'rxjs';
import { ApplicationDetailsState } from '@app/app/store/application-details/application-details.state';
import { Select, Store } from '@ngxs/store';
import { PortalUser } from '@app/app/interfaces/portal-user.model';
import { PortalUsersState } from '@app/app/store/portal-users/portal-users-state.service';
import { takeUntil } from 'rxjs/operators';
import { Offer } from '@app/app/interfaces/offer.model';
import { DeclineDialogComponent } from '@app/app/components/decline-button/decline-dialog/decline-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Approval } from '@app/app/interfaces/approval/approval.model';
import { ApprovalsState } from '@app/app/store/offers/approvals.state';
import { sortBy } from 'lodash';
import { SaasFeaturesState } from '@app/app/store/saas-features/saas-features.state';
import { ConfettiService } from '@app/app/services/confetti.service';
import { AuthUser } from '@app/app/store/auth/auth-user';
import { AuthState } from '@app/app/store/auth/auth.state';
import { EsignatureState } from '@app/app/store/esignature/esignature.state';
import { Esigner } from '@app/app/interfaces/esigner.model';
import { GetStatusesForSigners } from '@app/app/store/esignature/esignature.actions';
import { BusinessState } from '@app/app/store/businesses/business.state';
import { Business } from '@app/app/interfaces/business.model';
import { TemplateDialogComponent } from '@app/app/components/templates/template.dialog.component';
import { DecisioningState } from '@app/app/store/decisioning/decisioning.state';
import { DealStatus } from '../deal-status-change-dropdown/deal-status.enum';
import { ShowAanService } from './show-aan-service';
import {LaserProState} from "@app/app/store/laser-pro/laser-pro.state";

@Component({
  selector: 'app-deal-sidebar',
  templateUrl: './deal-sidebar.component.html',
  styleUrls: ['./deal-sidebar.component.scss']
})
export class DealSidebarComponent implements OnInit, OnDestroy {
  @Select(ApplicationDetailsState.applicationDetails) applicationDetails$: Observable<ApplicationDetails>;
  @Select(ApplicationDetailsState.dealApplicationDetails) dealApplicationDetails$: Observable<any | null>;
  @Select(ApprovalsState.approvals) approvals$: Observable<Approval[]>;
  @Select(AuthState.user) currentUser$: Observable<AuthUser>;
  @Select(PortalUsersState.portalUsers) portalUsers$: Observable<PortalUser[]>;
  @Select(SaasFeaturesState.saasPermitted('instantContracts')) hasInstantContractsFeature$: Observable<boolean>;
  @Select(SaasFeaturesState.saasPermitted('canViewAANDialog')) canViewAANDialog$: Observable<boolean>;
  @Select(EsignatureState.signingStatuses) signers$: Observable<Esigner[]>;
  @Select(SaasFeaturesState.saasPermitted('manualStatusChanges', 'lpxManuallyChangeDealStatus.write')) permittedToManuallyChangeStatus$: Observable<boolean>;
  @Select(BusinessState.businessTenantId) businessTenantId$: Observable<number>;
  @Select(DecisioningState.decisioningFailed) decisioningFailed$: Observable<boolean>;
  @Select(DecisioningState.decisioningHasOverrides) decisioningHasOverrides$: Observable<boolean>;
  @Select(DecisioningState.isEligbleForDecisioningRetry) isEligbleForDecisioningRetry$: Observable<boolean>;
  @Select(DecisioningState.isOverrideable) isOverrideable$: Observable<boolean>;

  @Input() dealId: number;
  @Input() applicationDetailsPage: string;
  @Input() isPushToLaserProButtonDisabled: boolean;

  @Output() overrideButtonClicked = new EventEmitter<void>();
  @Output() openDealHistoryButtonClicked = new EventEmitter<void>();
  @Output() retryDecisioningClicked = new EventEmitter<void>();
  @Output() applicationDetailsTabChange: EventEmitter<string> = new EventEmitter();
  @Output() pushToLaserProButtonClicked = new EventEmitter<void>();

  business$: Observable<Business> = this.store.select(BusinessState.business);
  laserProProcessing$: Observable<boolean> = this.store.select( LaserProState.laserProProcessing );
  laserProFailed$: Observable<boolean> = this.store.select( LaserProState.laserProFailed );
  laserProSucceeded$: Observable<boolean> = this.store.select( LaserProState.laserProSucceeded );

  destroyed$ = new Subject<boolean>();
  showAANButton$: Observable<boolean>;
  showOverrideButton$: Observable<boolean>;
  showRetryDecisioningButton$: Observable<boolean>;
  showPushToLaserProButton$: Observable<boolean>;
  // TODO: Remove canSeeMessagesTab$ once this goes live for everyone.
  canSeeMessagesTab$: Observable<boolean> = this.store.select(AuthState.userHasPermission('lpxCanSeeMessagesTab'));

  assignmentsArray: Array<number> = [];
  mostRecentOffer: Offer;
  mostRecentApproval: Approval;
  offerAccepted?: string;
  lendioOwnsBorrower?: boolean;
  aanSent = false;
  shouldDisplayDeclineBtn: boolean;
  shouldDisplayMakeOfferBtn: boolean;
  declinePages = ['new-apps', 'offer-sent', 'contract-sent', 'contract-signed'];
  newOfferPages = ['new-apps', 'offer-sent'];

  constructor(
    protected _confettiService: ConfettiService,
    private dialog: MatDialog,
    private store: Store,
    private showAanService: ShowAanService
  ) {
    this.showAANButton$ = combineLatest([
      this.canViewAANDialog$,
      this.applicationDetails$,
    ]).pipe(
      takeUntilDestroyed(),
      map(([
        canViewAANDialog,
        applicationDetails,
      ]) => {
        return canViewAANDialog
          && applicationDetails.deal?.status === DealStatus.Dead
      }),
    );

    this.showOverrideButton$ = combineLatest([
      this.applicationDetails$,
      this.currentUser$,
      this.isOverrideable$
    ]).pipe(
      takeUntilDestroyed(),
      map(([
        applicationDetails,
        currentUser,
        isOverrideable,
      ]) => {
        return applicationDetails?.deal?.status === DealStatus.DoesNotQualify &&
          currentUser.permittedTo('lpxOverrideDecisioningQualifiers') &&
          isOverrideable
      }),
    );

    this.showAanService.showAanDialog.pipe(takeUntilDestroyed()).subscribe(state => {
      if (state) {
        setTimeout(() => this.reviewAAN(), 750);
      }
    })
  }

  /**
   * Ng lifecycle
   * @return void
   */
  ngOnInit(): void {
    this.shouldDisplayDeclineBtn = this.declinePages.indexOf(this.applicationDetailsPage) !== -1;
    this.shouldDisplayMakeOfferBtn = this.newOfferPages.indexOf(this.applicationDetailsPage) !== -1;

    this.store.dispatch(new GetStatusesForSigners(this.dealId));
    this.approvals$.pipe(
      takeUntil(this.destroyed$)
    ).subscribe(approvals => {
      if (approvals?.length) {
        const dealApprovals = approvals.filter(approval => approval.dealId === this.dealId);
        this.mostRecentApproval = sortBy(dealApprovals, 'created')[0];
      }
    });

    this.applicationDetails$.pipe(
      takeUntil(this.destroyed$)
    ).subscribe(applicationDetails => {
      this.assignmentsArray = applicationDetails?.deal?.assignments
        ? JSON.parse(applicationDetails.deal.assignments)
        : [];
      this.mostRecentOffer = applicationDetails?.deal?.offers_desc[0];
      this.mostRecentOffer = applicationDetails?.deal?.offers_desc[0] || null;
    });

    this.businessTenantId$.pipe(
      takeUntil(this.destroyed$)
    ).subscribe((tenantId) => {
      this.lendioOwnsBorrower = (tenantId === 1)
    });

    this.showRetryDecisioningButton$ = combineLatest([
      this.applicationDetails$,
      this.dealApplicationDetails$,
      this.currentUser$,
    ]).pipe(
      takeUntil(this.destroyed$),
      map(([
        applicationDetails,
        dealApplicationDetails,
        currentUser,
      ]) => {
        return applicationDetails?.deal?.status === DealStatus.DoesNotQualify &&      // is this a dnq deal?
          this.isApplicationLessThanThirtyDaysOld(dealApplicationDetails.created) && // is the app less than thirty days old?
          currentUser.permittedTo('lpxOverrideDecisioningQualifiers')                // and is the user permitted?
      }),
    );

    this.showPushToLaserProButton$ = combineLatest([
      this.applicationDetails$,
      this.dealApplicationDetails$,
      this.currentUser$,
      this.laserProSucceeded$
    ]).pipe(
      takeUntil(this.destroyed$),
      map(([
             applicationDetails,
             dealApplicationDetails,
             currentUser,
             succeeded,
           ]) => {
        return !succeeded &&                                                          // hide button when succeeded
          applicationDetails?.deal?.status === DealStatus.ContractIn &&                // is this a contract signed deal?
          this.isApplicationLessThanThirtyDaysOld(dealApplicationDetails.created) &&  // is the app less than thirty days old?
          currentUser.permittedTo('canPushToLaserPro')                      // and is the user permitted?
      }),
    );
  }

  /**
   * Ng lifecycle
   * @return void
   */
  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  /**
   * @returns void
   */
  openDeclineDialog(): void {
    this.dialog.open(DeclineDialogComponent, {
      width: '450px',
      data: { dealId: this.dealId },
    });
  }

  /**
   * @param status
   * @returns string
   */
  formatStatus(status: string): string {
    if (!status) {
      return '';
    }
    const withSpaces = status.replace(/([A-Z])/g, ' $1');
    return withSpaces.charAt(0).toUpperCase() + withSpaces.slice(1).toLowerCase();
  }

  reviewAAN() {
    combineLatest([
      this.business$,
      this.applicationDetails$,
    ]).pipe(
      take(1),
    ).subscribe(([
      business,
      applicationDetails,
    ]) => {
      const dialogRef = this.dialog.open(TemplateDialogComponent, {
        data: {
          business,
          deal: applicationDetails.deal,
        },
      });

      dialogRef
        .afterClosed()
        .subscribe((dialogResult: string | undefined) => {
          if (dialogResult === 'AAN sent successfully') {
            this.aanSent = true;
          }
        });
    });
  }

  /**
   * @param tabLabel
   */
  changeApplicationDetailTab(tabLabel: string): void {
    this.applicationDetailsTabChange.emit(tabLabel)
  }

  /**
   * Determine if the deals current application is less than 30 days old
   * @param created
   * @return boolean
   */
  private isApplicationLessThanThirtyDaysOld(created: string): boolean {
    const createdDate = new Date(created);
    const currentDate = new Date();
    const differenceInMilliseconds = currentDate.getTime() - createdDate.getTime();
    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

    return differenceInDays < 30;
  }
}
