import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { Store } from '@ngxs/store';
import { DocumentsActions } from '@app/app/store/documents/documents.actions';
import { Document } from '@app/app/interfaces/document.model';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { ReactiveFormsModule, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-document-rejection-dialog',
  templateUrl: './document-rejection-dialog.component.html',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule
  ]
})

export class DocumentRejectionDialogComponent implements OnInit {
  @Output() onSave = new EventEmitter<void>();
  @Output() onCancel = new EventEmitter<void>();
  form: FormGroup;
  reason: string;

  constructor(
    private store: Store,
    @Inject(MAT_DIALOG_DATA) public data: {
      document: Document,
      dealId: number
    },
    public selfDialogRef: MatDialogRef<DocumentRejectionDialogComponent>,
  ) {}

  ngOnInit(): void {
    this.form = new FormGroup({
      reason: new FormControl(this.reason, [Validators.required, Validators.minLength(10)])
    });

  }

  save(form: FormGroup) {
    if (form.value.reason) {
      this.store.dispatch(new DocumentsActions.RejectDealDocument(this.data.dealId, this.data.document.id, form.value.reason))
        .subscribe({
          next: () => {
            this.selfDialogRef.close();
            this.onSave.emit();
            this.selfDialogRef.close();
        }
      });
    }
  }

  cancel($event: Event) {
    $event.stopPropagation();
    this.selfDialogRef.close();
  }
}
