<div data-cy="document-rejection-dialog" *ngIf="form">
    <div class="flex justify-between content-center items-center">
        <h2 mat-dialog-title class="leading-none" data-cy="cancel-document-rejection-header">
            Document rejection confirmation
        </h2>
        <button class="py-6 pr-6"(click)="cancel($event)">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <mat-dialog-content>
        <div>
            <p>
                Are you sure you want to reject this document? Rejecting the document will notify the borrower, 
                prompting them to upload a new or revised version.
            </p>
            <p>
                Please add notes explaining the reasons for the rejection and/or instructions for proper completion 
                of this document requirement.
            </p>
        </div>
        <form [formGroup]="form" class="lendio-density-1">
            <mat-form-field appearance="fill" [hideRequiredMarker]="true" required class="w-full">
                <input class="w-full" matInput formControlName="reason" type="text" placeholder="Note to borrower" data-cy="reason" required>
                @if (form.get('reason') && !form.get('reason').valid) {
                    <mat-error>You must enter a message (10 or more characters)</mat-error>
                    }
            </mat-form-field>
        </form>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button color="primary" (click)="cancel($event)">
            Cancel
        </button>
        <button mat-flat-button color="primary" class="!ml-4"
            data-cy="document-rejection-confirm-btn"
            [disabled]="!form.dirty || !form.valid"
            (click)="save(form)"
        >
        Confirm rejection 
        </button>
    </mat-dialog-actions>
</div>
