import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@app/environments/environment";
import { LendioResponse } from '@app/app/interfaces/lendio-response';
import { map } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class IntercomService {

    constructor(private http: HttpClient) {}

    public getIntercomHash() {
        return this.http.get<LendioResponse<string>>(`${environment.apiUrl}/intercom/gethash`).pipe(map(response => response));
    }
}