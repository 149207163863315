import {
  CheckboxSelectionCallbackParams,
  ColDef,
  GetRowIdParams,
  GridOptions,
  HeaderCheckboxSelectionCallbackParams,
  ModelUpdatedEvent,
  SizeColumnsToContentStrategy,
  SizeColumnsToFitGridStrategy,
  SizeColumnsToFitProvidedWidthStrategy,
  ToolPanelDef,
  RowNode
} from 'ag-grid-community';
import { ColumnHeaderComponent } from '@app/app/components/grid-components/column-header/column-header.component';
import { ColumnsToolPanelComponent } from '@app/app/components/grid-components/columns-tool-panel/columns-tool-panel.component';
import { NoDocumentsOverlayComponent } from './no-documents-overlay/no-documents-overlay.component';
import { TooltipCellComponent } from '@app/app/components/grid-components/grid-tooltip/grid-tooltip.component';

export const COLUMNS_ICON_ELEMENT_REF = `<span class="ag-icon ag-icon-columns"></span>`;

export const AUTO_SIZE_STRATEGY:
  | SizeColumnsToFitGridStrategy
  | SizeColumnsToFitProvidedWidthStrategy
  | SizeColumnsToContentStrategy = {
  type: 'fitGridWidth',
  defaultMinWidth: 100,
  columnLimits: [
    {
      colId: 'filename',
      minWidth: 380,
    },
    {
      colId: 'accepted',
      minWidth: 200
    }
  ],
};

export const DOCUMENTS_CUSTOM_COLUMNS_TOOL_PANEL_DEF: ToolPanelDef = {
  id: 'documentColumns',
  labelDefault: 'documentColumns',
  labelKey: 'documentColumns',
  iconKey: 'columns',
  toolPanel: ColumnsToolPanelComponent
};

export const  COMPONENTS: { [p: string]: any; } = {
  agColumnHeader: ColumnHeaderComponent,
};

export const DOCUMENTS_GRID_BASE_CONFIG: GridOptions = {
  noRowsOverlayComponent: NoDocumentsOverlayComponent,
  onModelUpdated: (event: ModelUpdatedEvent) => {
    event.api.getDisplayedRowCount() === 0 ?
      event.api.showNoRowsOverlay() :
      event.api.hideOverlay();
  },
  animateRows: false,
  autoSizeStrategy: AUTO_SIZE_STRATEGY,
  autoSizePadding: 8,
  cacheQuickFilter: true,
  columnMenu: 'new',
  enableCellTextSelection: true,
  groupDisplayType: 'groupRows',
  popupParent: document.body,
  rowSelection: 'multiple',
  sideBar: { toolPanels: [DOCUMENTS_CUSTOM_COLUMNS_TOOL_PANEL_DEF] },
  suppressAggFuncInHeader: true,
  suppressCellFocus: true,
  suppressRowClickSelection: true,
  getRowId: (params: GetRowIdParams) => (params.data.id.toString()),
  components: COMPONENTS,
  isRowSelectable(rowNode: RowNode): boolean {
    return rowNode.data.scanStatus === 'CLEAN';
  },
  tooltipShowDelay: 500,
  tooltipHideDelay: 2500
};

function isFirstColumn(params:
    | CheckboxSelectionCallbackParams
    | HeaderCheckboxSelectionCallbackParams,
) {
  const displayedColumns = params.api.getAllDisplayedColumns();
  return displayedColumns[0] === params.column;
}

export const DOCUMENTS_GRID_BASE_DEFAULT_COL_DEF: ColDef = {
  columnChooserParams: {suppressSyncLayoutWithGrid: true},
  suppressHeaderFilterButton: false,
  suppressHeaderContextMenu: true,
  checkboxSelection: isFirstColumn,
  headerCheckboxSelectionFilteredOnly: true,
  filterParams: {
    maxNumConditions: 1,
    suppressMiniFilter: true
  },
  tooltipComponent: TooltipCellComponent,
  tooltipValueGetter: params => {
    if (params.data.scanStatus !== 'CLEAN') {
      return 'Document disabled: virus scan pending.';
    } 
    return null;
  }
};
