<div (click)="$event.stopPropagation()" class="dynamic-document-wrapper">
@if (mouseOver && scanIsClean) {
    <div [ngClass]="{ 'flex-end': rejected, 'flex-start': !rejcted }" class="flex flex-row">
        <div class="flex items-center">
            <button (click)="handleAccept($event)" 
                    [disabled]="rejected"
                    class="flex items-center justify-center mr-1">
                <mat-icon [matTooltip]="accepted ? 'Undo acceptance' : (rejected ? '' : 'Accept document')" 
                        matTooltipPosition="below"
                        matTooltipShowDelay="500"
                        [class]="accepted ? 'text-green-400': (rejected ? 'invisible' : '')"
                        class="material-icons-outlined history-icon">
                        check
                </mat-icon>
            </button>
            <button [matTooltip]="rejected ? '' : 'Reject document'" 
                    matTooltipPosition="below"
                    matTooltipShowDelay="500"
                    [disabled]="rejected"
                    (click)="handleReject($event)"
                    class="flex items-center mr-1">
                <mat-icon [class]="rejected ? 'invisible' : ''" class="material-icons-outlined">close</mat-icon>
            </button>
            <button [matTooltip]="scanIsClean ? 'Download' : 'Download disabled: virus scan pending.'" 
                    matTooltipPosition="below"
                    matTooltipShowDelay="500"
                    [disabled]="!scanIsClean"
                    (click)="handleDownload($event)"
                    class="flex items-center mr-1">
                <mat-icon [class]="!scanIsClean ? 'text-lendio-warm-gray-400' : ''" class="material-icons-outlined">file_download</mat-icon>
            </button>
            <button [matTooltip]="!canEditAndDelete ? 'You do not have delete permissions' : 'Edit'" 
                    matTooltipPosition="below"
                    matTooltipShowDelay="500"
                    [disabled]="!canEditAndDelete"
                    (click)="handleEdit($event)"
                    class="flex items-center mr-1">
                <mat-icon [class]="!canEditAndDelete ? 'text-lendio-warm-gray-400' : ''" class="material-icons-outlined">edit</mat-icon>
            </button>
            <button [matTooltip]="!canEditAndDelete ? 'You do not have delete permissions' : 'Delete document'" 
                    matTooltipPosition="below"
                    matTooltipShowDelay="500"
                    [disabled]="!canEditAndDelete"
                    (click)="handleDelete($event)"
                    class="flex items-center">
                <mat-icon [class]="!canEditAndDelete ? 'text-lendio-warm-gray-400' : ''" class="material-icons-outlined">delete</mat-icon>
            </button>
        </div>
    </div>
} @else {
    <div *ngIf="accepted" 
         [matTooltip]="'Accepted'" 
         matTooltipPosition="below"
         matTooltipShowDelay="500"
         class="flex items-center text-center bg-green-400 border-none rounded-xl h-5 w-5"
    >
        <mat-icon class="material-icons-outlined mt-[6px] text-xs text-white">check</mat-icon>
    </div>
    <div *ngIf="rejected"
         [matTooltip]="'Rejected'" 
         matTooltipPosition="below"
         matTooltipShowDelay="500" 
         class="flex items-center text-center bg-red-400 border-none rounded-xl h-5 w-5"
    >
        <mat-icon class="material-icons-outlined mt-[8px] text-xs text-white">close</mat-icon>
    </div>
}
</div>
