@if (filtersChecked()) {
    <div class="flex flex-col justify-center items-center gap-4">
        <img src="assets/images/empty-state-svgs/empty-documents.svg" alt="No documents to show">
        @if (noDocumentsMatchFilters()) {
            <div class="mat-subtitle-2">No documents were found.</div>
            <div class="mat-body-2">Please try adjusting your filters.</div>

        } @else {
            <div class="mat-subtitle-2">There are no documents.</div>
        }
    </div>
}
