import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { GetIntercomHash } from "./intercom.actions";
import { IntercomService } from "../../services/intercom.service";
import { catchError, tap, throwError } from "rxjs";

export class IntercomStateModel {
    hash: string | null;
    hideDefaultLauncher: boolean;
}

@Injectable()
@State<IntercomStateModel>({
    name: 'intercom',
    defaults: {
        hash: null,
        hideDefaultLauncher: true,
    }
})
export class IntercomState {

    constructor(
        private intercomService: IntercomService,
    ) {}

    @Selector()
    static intercom(state: IntercomStateModel): IntercomStateModel {
        return state;
    }

    @Selector()
    static hash(state: IntercomStateModel): string | null {
        return state.hash;
    }

    @Action(GetIntercomHash)
    getIntercomHash(
        ctx: StateContext<IntercomStateModel>,
        {}: GetIntercomHash
    ) {
        const hash = ctx.getState().hash;
        if(!hash) {
            return this.intercomService.getIntercomHash().pipe(
                catchError(err => {
                    ctx.patchState({
                        hash: undefined
                    });
                    return throwError(err);
                }),
                tap( response => {
                    ctx.patchState({
                        hash: response.data
                    });
                }),
            )
        }
    }
}