import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { SentenceCasePipe } from '@app/app/pipes/sentence-case.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DEAL_STATUS_OPTIONS_BY_STAGE } from '../../deal-status-change-dropdown/deal-status-options-by-stage';
import { DealStage } from '../../deal-status-change-dropdown/deal-stage.enum';
import { StatusOption } from '@app/app/interfaces/status-option';

@Component({
  standalone: true,
  selector: 'lendio-deal-stage-display',
  templateUrl: './lendio-deal-stage-display.component.html',
  styleUrl: './lendio-deal-stage-display.component.scss',
  imports: [CommonModule, MatTooltipModule, SentenceCasePipe],
  providers: [SentenceCasePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LendioDealStageDisplayComponent implements OnInit {
  @Input({ required: true }) public stage: string;
  @Input({ required: true }) public status: string;

  protected _stageStatusDisplay: string;

  constructor(private _sentenceCasePipe: SentenceCasePipe) {}

  ngOnInit(): void {
    const stage = this._sentenceCasePipe.transform(this.stage || '');
    const status = this.getStatusDisplayValue(this.status || '');
    this._stageStatusDisplay = `${stage}: ${status}`;
  }

  getStatusDisplayValue(value: string): string | null {
    for (const stage in DEAL_STATUS_OPTIONS_BY_STAGE) {
      const statusOptions = DEAL_STATUS_OPTIONS_BY_STAGE[stage as DealStage];
      
      const matchingOption = statusOptions.find(option => option.value === value);
      if (matchingOption) {
        return matchingOption.display;
      }
    }

    return null;
  }
}
