<div class="loading" *ngIf="loading; else ready" [@fadeIn]>
    <app-dot-loader-scale style="margin: 0 auto"></app-dot-loader-scale>
</div>

<ng-template #ready>
    <div [@fadeIn]>
        <div *ngIf="alerts && isQuickView">
            <app-alert
                *ngFor="let alert of alerts"
                [level]="alert.level"
                [message]="alert.message"
                [dismissible]="true"
                (dismissed)="dismissAlert(alert)"
            ></app-alert>
        </div>
        <span
            class="flex items-center pb-2 text-blue-500 hover:text-blue-700 text-base cursor-pointer"
            (click)="goBack()"
            *ngIf="page && !isQuickView && page != 'New Apps'"
            ><mat-icon>navigate_before</mat-icon> Back to {{ page }}</span
        >
        <span
            class="flex items-center pb-2 text-blue-500 hover:text-blue-700 text-base cursor-pointer"
            (click)="goBack()"
            *ngIf="page && !isQuickView && page == 'New Apps'"
            ><mat-icon>navigate_before</mat-icon> Back to New</span
        >
        <app-section-header
            [applicationDetails]="applicationDetails$ | async"
            [loanProductCategory]="loanProductCategory"
            [lenderLabels]="lenderLabels$ | async"
            [portalUsers]="portalUsers$ | async"
            [isRenewal]="isRenewal">
        </app-section-header>
        <div class="flex">
            <div class="col-70">
                <mat-card appearance="outlined" class="mr-4 !pt-1">
                    <mat-tab-group
                        mat-stretch-tabs="true"
                        mat-align-tabs="start"
                        [(selectedIndex)]="selectedIndex"
                        animationDuration="0ms"
                    >
                        <mat-tab>
                            <ng-template mat-tab-label
                                ><span
                                    [ngClass]="{
                                        'font-bold': selectedIndex === 0
                                    }"
                                    >Details</span
                                ></ng-template
                            >
                            <app-old-details
                                *ngIf="
                                    loanProductCategory !== 'SBA PPP' &&
                                    loanProductCategory !== 'SBA PPP Second'
                                "
                                [applicationDetails]="
                                    applicationDetails$ | async
                                "
                                [applicationFields]="applicationFields$ | async"
                            >
                            </app-old-details>
                            <app-ppp-details
                                *ngIf="
                                    loanProductCategory === 'SBA PPP' ||
                                    loanProductCategory === 'SBA PPP Second'
                                "
                                [applicationDetails]="
                                    applicationDetails$ | async
                                "
                                [loanProductCategory]="loanProductCategory"
                                [applicationFields]="applicationFields$ | async"
                            >
                            </app-ppp-details>
                        </mat-tab>
                        <mat-tab>
                            <ng-template mat-tab-label
                                ><span
                                    [ngClass]="{
                                        'font-bold': selectedIndex === 1
                                    }"
                                    >Documents</span
                                ></ng-template
                            >
                            <app-documents-smart
                                [dealId]="dealId"
                                [borrowerLenderId]="businessLenderId$ | async"
                                (smartDocumentError)="smartDocumentError($event)"
                            ></app-documents-smart>
                        </mat-tab>
                        <mat-tab>
                            <ng-template mat-tab-label
                                ><span
                                    [ngClass]="{
                                        'font-bold': selectedIndex === 2
                                    }"
                                    data-cy="offers-tab"
                                    >Offers</span
                                ></ng-template
                            >
                            <app-offers-smart
                                [dealId]="dealId"
                            ></app-offers-smart>
                        </mat-tab>
                        <mat-tab>
                            <ng-template mat-tab-label
                                ><span
                                    [ngClass]="{
                                        'font-bold': selectedIndex === 3
                                    }"
                                    data-cy="approvals-tab"
                                    >Approvals</span
                                ></ng-template
                            >
                            <ng-template matTabContent>
                                <app-approval-smart
                                    [useStoreApprovals]="true"
                                    [dealId]="dealId"
                                ></app-approval-smart>
                            </ng-template>
                        </mat-tab>
                        <mat-tab
                            *ngIf="
                                loanProductCategory == 'SBA PPP' ||
                                loanProductCategory === 'SBA PPP Second'
                            "
                        >
                            <ng-template mat-tab-label
                                ><span
                                    data-cy="owners-tab"
                                    [ngClass]="{
                                        'font-bold': selectedIndex === 4
                                    }"
                                    >Owners</span
                                ></ng-template
                            >
                            <app-owners-smart
                                [dealId]="dealId"
                            ></app-owners-smart>
                        </mat-tab>
                        <mat-tab
                            *ngIf="
                                loanProductType === 'ach' ||
                                loanProductType === 'loc' ||
                                loanProductType === 'term'
                            "
                        >
                            <ng-template mat-tab-label
                                ><span
                                    [ngClass]="{
                                        'font-bold': selectedIndex === 5
                                    }"
                                    >Contract Request</span
                                ></ng-template
                            >
                            <app-contract-requests-smart
                                [dealId]="dealId"
                            ></app-contract-requests-smart>
                        </mat-tab>
                    </mat-tab-group>
                </mat-card>
            </div>
            <div class="col-30">
                <mat-card
                    appearance="outlined"
                    class="mb-4"
                    *ngIf="
                        ((applicationDetails$ | async).deal
                            .lenderOwnsPPPBorrower &&
                            (applicationDetails$ | async).deal.status ==
                                'awaitingResponse') ||
                        ((applicationDetails$ | async).deadReason ===
                            'Sent to Marketplace' &&
                            (applicationDetails$ | async).deal.status ==
                                'doesNotQualify')
                    "
                >
                </mat-card>
                <app-ppp-status
                    *ngIf="
                        loanProductCategory === 'SBA PPP' ||
                        loanProductCategory === 'SBA PPP Second'
                    "
                    [applicationDetails]="applicationDetails$ | async"
                >
                </app-ppp-status>
                <app-old-lendio-rep
                    [isFundingDeskRep]="true"
                    [applicationDetails]="applicationDetails$ | async"
                >
                </app-old-lendio-rep>
                <app-old-lendio-rep
                    [isLendioTeamLead]="true"
                    [applicationDetails]="applicationDetails$ | async"
                ></app-old-lendio-rep>

                <!-- Only show for Marketplace deals -->
                @if (isMarketplaceBorrower()) {
                    <mat-card appearance="outlined" class="fd-card">
                        <app-funding-desk-smart
                            [dealId]="dealId"
                            [newTheme]="false"
                            [isSidebar]="true"
                        ></app-funding-desk-smart>
                    </mat-card>
                }
            </div>
        </div>
    </div>
</ng-template>
