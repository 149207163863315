import { Component, OnInit } from '@angular/core';
import { LendioAngularMaterialThemeService } from 'src/app/components/lendio-angular-material-theme/lendio-angular-material-theme.service';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  templateUrl: './application-details-resolver.component.html',
})
export class ApplicationDetailsResolverComponent implements OnInit {
  newThemeEnabled$: Observable<boolean> = this._themeService.enabled;
  dealId: number;
  constructor(
    private _themeService: LendioAngularMaterialThemeService,
    private _activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    const dealIdParam = this._activatedRoute.snapshot.paramMap.get('id');
    if (dealIdParam) {
      this.dealId = parseInt(dealIdParam);
    }
  }
}
