import { Component, Input } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { MatIconModule } from '@angular/material/icon';
import { TruncatePipeModule } from '@app/app/pipes/truncate/truncate-pipe.module';
import { environment } from '@app/environments/environment';
import { DocumentsService } from '@app/app/services/documents.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCommonModule } from '@angular/material/core';

interface FilenameCellParams extends ICellRendererParams {
  dealId?: number;
}
@Component({
    selector: 'grid-filename-cell',
    standalone: true,
    imports: [
      MatCommonModule,
      MatIconModule,
      MatTooltipModule,
      MatProgressSpinnerModule,
      TruncatePipeModule
    ],
    templateUrl: './filename-cell.component.html'
})
export class FileNameCellComponent implements ICellRendererAngularComp {
    public dealId?: number;
    public filename: string;
    public scanIsClean: boolean;
    public params: FilenameCellParams;

    constructor (private doc: DocumentsService) {
    }

    agInit(params: ICellRendererParams): void {
      this.params = params;
      this.filename = this.params.data.filename;
      this.scanIsClean = this.params.data.scanStatus === 'CLEAN';
    }

    ngOnInit() {
      this.dealId = this.params.dealId;
    }

    value(): string {
      return this.params.value;
    }

    refresh(params: ICellRendererParams) {
      this.params = params;
      this.filename = this.params.data.filename;
      this.scanIsClean = this.params.data.scanStatus === 'CLEAN';
      return true;
    }

    viewDocument() {
      const docId = this.params?.data.id;
      this.doc.openInNewTab(this.documentUrl(docId), docId);
    }

    private documentUrl(docId: number): string {
      if (this.dealId) {
        return `${environment.apiUrl}/l/v2/internal/document/${docId}/stream?dealId=${this.dealId}`;
      } else {
        return `${environment.apiUrl}/document/${docId}?stream=1`;
      }
    }
}
