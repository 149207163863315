@if (notes$ | async; as notes) {
    @if (notes.length) {
        <mat-card appearance="outlined" class="w-[734px] justify-self-center mt-3">
            <mat-card-header>
                <mat-card-title-group>
                    <mat-card-title>Notes</mat-card-title>
                    <button
                        mat-button
                        (click)="openAddNote()"
                        color="primary">
                        <!-- TODO: Add E2E test for Notes in tab -->
                        <!-- data-cy="add-note-button"> -->
                        Add
                    </button>
                </mat-card-title-group>
            </mat-card-header>
            <mat-card-content>
                <div class="note-container p-2 mb-2 mt-4 last:mb-4 border border-solid rounded-xl border-lendio-warm-gray-300"
                     *ngFor="let note of notes"
                     matTooltip="{{ (note.created | date:'MMMM d, y \'at\' h:mma') || '' }}"
                >
                    <div class="text-xs">{{ note.content }}</div>
                    <!-- TODO: Add E2E test for Notes in tab -->
                    <!-- <div data-cy="deal-note-text">{{note.content}}</div> -->
                    <div class="mt-4 text-xs text-gray-400">
                        {{ note.lenderUserName }}
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    } @else {
        <mat-card appearance="outlined" class="w-[734px] justify-self-center mt-3">
            <mat-card-content>
                <app-empty-state
                    type="offers-contracts"
                    label="There are no notes."
                >
                    <div class="text-center font-normal text-gray-900 mt-2" messageContent>
                        Please <a class="text-blue-500 hover:text-blue-800 cursor-pointer" (click)="openAddNote()">add a note</a> to get going.
                    </div>
                </app-empty-state>
            </mat-card-content>
        </mat-card>
    }
}
