<div class="funnel-columns-tool-panel">
    <div class="funnel-columns-controls lendio-density-2">
        <div class="w-full flex flex-row justify-between items-center">
            <div class="mat-subtitle-2">Choose columns</div>
            <button mat-icon-button (click)="closeSidebar()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <mat-form-field>
            <mat-icon matPrefix>search</mat-icon>
            <input matInput
                   type="text"
                   placeholder="Search columns..."
                   [(ngModel)]="searchQuery"
                   (ngModelChange)="onColumnFilterInput()">
        </mat-form-field>
    </div>
    <div class="funnel-column-list">
        @for (column of filteredColumns; track column.colDef.colId) {
            @if (!column.colDef.suppressColumnsToolPanel) {
                <mat-checkbox color="accent"
                              [(ngModel)]="column.visible"
                              (change)="onColumnVisibilityChange()">
                    {{ column.colDef.headerName }}
                </mat-checkbox>
            }
        } @empty {
            <div class="mat-body-2 pl-4">No matching columns</div>
        }
    </div>
</div>
