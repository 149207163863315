@if(newTheme) {
    @if (isSidebar) {
        <app-funding-desk-sidebar
            [fundingDeskRequests]="fundingDeskRequests$ | async"
            (createNew)="createNewRequest($event)"
            (newComment)="createNewComment($event)"
        ></app-funding-desk-sidebar>
    } @else {
        <app-funding-desk
            (createNew)="createNewRequest($event)"
            (newComment)="createNewComment($event)"
        ></app-funding-desk>
    }
} @else {
    <app-funding-desk-old
        [fundingDeskRequests]="fundingDeskRequests$ | async"
        (createNew)="createNewRequest($event)"
        (newComment)="createNewComment($event)"
    ></app-funding-desk-old>
}
