import { Component } from '@angular/core';
import { ITooltipAngularComp } from 'ag-grid-angular';
import { ITooltipParams } from 'ag-grid-community';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCommonModule } from '@angular/material/core';

@Component({
    selector: 'grid-filename-cell',
    standalone: true,
    imports: [
      MatCommonModule,
      MatTooltipModule
    ],
    templateUrl: './grid-tooltip.component.html'
})
export class TooltipCellComponent implements ITooltipAngularComp {
    public tooltip?: string;
    public params: ITooltipParams;

    constructor () {
    }

    agInit(params: ITooltipParams): void {
      this.params = params;
      this.tooltip = this.value();
    }

    value(): string {
      return this.params.value;
    }

    refresh(params: ITooltipParams) {
      return false;
    }
}
