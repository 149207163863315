import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@app/environments/environment';
import { LendioResponse } from '@app/app/interfaces/lendio-response';
import { AgGridViewFilter } from '@app/app/store/funnel-views/funnel-views.state';

const viewFiltersUrl = `${environment.apiUrl}/lender-portal/ag-grid-views`;

export interface AgGridViewsResponse {
  data: AgGridViewFilter[];
}

@Injectable({
  providedIn: 'root'
})
export class FunnelViewFiltersService {
  constructor(private _http: HttpClient) { }

  getFunnelViews(tableName = 'funnel'): Observable<AgGridViewsResponse> {
    return this._http.get<AgGridViewsResponse>(`${viewFiltersUrl}/${tableName}`, {});
  }

  updateFunnelViews(viewFilters, tableName = 'funnel'): Observable<LendioResponse> {
    const funnelViews: AgGridViewFilter[] = [];
    viewFilters.forEach(viewFilter => {
      funnelViews.push({
        name: viewFilter.name,
        isCreatedByUser: viewFilter.isCreatedByUser,
        isHiddenByUser: viewFilter.isHiddenByUser,
        agGridViewId: viewFilter.agGridViewId,
        filterModel: viewFilter.filterModel
      })
    })
    return this._http.put<LendioResponse>(`${viewFiltersUrl}/${tableName}`, { funnelViews });
  }
}
