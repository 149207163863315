import { LenderTask } from "@app/app/interfaces/lender-task.model";

// Require at least one of assigned/read to be provided
export type LenderTaskEnrollmentParams = {
  lenderActionItemId: number;
  userId: number;
} & (
  | { assigned: boolean; read?: boolean }
  | { assigned?: boolean; read: boolean }
);

export class GetLenderTasks {
  static readonly type = '[LenderTasks] GetLenderTasks';
  constructor() {}
}

export class CreateOrUpdateLenderTaskEnrollment {
  static readonly type = '[LenderTasks] CreateOrUpdateLenderTaskEnrollment';
  constructor(public lenderTaskEnrollmentParams: LenderTaskEnrollmentParams) {}
}

export class ClearLenderTasksState {
  static readonly type = '[LenderTasks] ClearLenderTasksState';
  constructor() {}
}

export class AddNewLenderTask {
  static readonly type = '[LenderTasks] AddNewLenderTask';
  constructor(
    public lenderTask: LenderTask,
  ) {}
}

export class UpdateLenderTask {
  static readonly type = '[LenderTasks] UpdateLenderTask';
  constructor(
    public lenderTask: LenderTask,
  ) {}
}

export class SubscribeToLenderTaskUpdates {
  static readonly type = '[LenderTasks] SubscribeToLenderTaskUpdates';
  constructor(
  ) {}
}
export class UnsubscribeFromLenderTaskUpdates {
  static readonly type = '[LenderTasks] UnsubscribeFromLenderTaskUpdates';
  constructor(
  ) {}
}

export class SetLenderTaskToBeCompleted {
  static readonly type = '[LenderTasks] SetLenderTaskToBeCompleted';
  constructor(
    public lenderTask: LenderTask,
    public successMessage: string | null
  ) {}
}
