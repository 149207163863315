import {
  ColDef,
  GetMainMenuItemsParams,
  GridApi,
  GridOptions,
  MenuItemDef,
} from 'ag-grid-community';
import {
  COLUMNS_ICON_ELEMENT_REF,
  DOCUMENTS_GRID_BASE_CONFIG,
  DOCUMENTS_GRID_BASE_DEFAULT_COL_DEF,
} from './documents-grid-constants';

export const quickFilterParser = (quickFilter: string): string[] => quickFilter.split(',');
export const quickFilterMatcher = (quickFilterParts: string[], rowQuickFilterAggregateText: string): boolean => {
  return quickFilterParts.every((part: string) => rowQuickFilterAggregateText.match(part));
};

export const mainMenuItems = (params: GetMainMenuItemsParams) => {
  const menuItems: (MenuItemDef | string)[] = [];
  const itemsToExclude = [
    'valueAggSubmenu',
    'columnChooser'
  ];
  params.defaultItems.forEach((item) => {
    if (itemsToExclude.indexOf(item) < 0) {
      menuItems.push(item);
    }
  });
  menuItems.push({
    name: 'Choose Column',
    icon: COLUMNS_ICON_ELEMENT_REF,
    action: (params) => {
      if (params.api.isToolPanelShowing()) {
        params.api.closeToolPanel();
      } else {
        params.api.openToolPanel('documentColumns');
      }
    }
  })
  return menuItems;
};

export const documentsSizeColsToFit = (api: GridApi) => {
  api.sizeColumnsToFit({
    defaultMinWidth: 200,
    columnLimits: [
      {
        key: 'fileName',
        minWidth: 300,
      }
    ]
  });
};

export const documentsGridConfig: GridOptions = {
  ...DOCUMENTS_GRID_BASE_CONFIG,
  quickFilterMatcher,
  quickFilterParser
}

export const documentsGridDefaultColDef: ColDef = {
  ...DOCUMENTS_GRID_BASE_DEFAULT_COL_DEF,
  mainMenuItems
}
