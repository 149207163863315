import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, filter, map, Observable, Subject } from 'rxjs';
import { ConsumerCreditReport } from '@app/app/interfaces/credit-reports/credit-report.model';
import { take, takeUntil } from 'rxjs/operators';
import { FinancesState } from '@app/app/store/finances/finances.state';
import { Select, Store } from '@ngxs/store';
import { GetCommercialCreditReport, GetOwnerCreditReport } from '@app/app/store/finances/finances.actions';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '@app/app/components/dialogs/confirm-dialog/confirm-dialog.component';
import { DocumentsState } from '@app/app/store/documents/documents.state';
import { DocumentsService } from '@app/app/services/documents.service';
import { Business } from '@app/app/interfaces/business.model';
import { BusinessState } from '@app/app/store/businesses/business.state';
import { AuthUser } from '@app/app/store/auth/auth-user';
import { AuthState } from '@app/app/store/auth/auth.state';
import { environment } from '@app/environments/environment';
import { addDays, isAfter } from 'date-fns';
import { Document } from '@app/app/interfaces/document.model';
import { SaasFeaturesState } from '@app/app/store/saas-features/saas-features.state';

@Component({
  selector: 'app-credit-score-display',
  templateUrl: 'credit-score-display.component.html',
  styleUrls: ['credit-score-display.component.scss'],
})
export class CreditScoreDisplayComponent implements OnChanges, OnInit, OnDestroy {

  @Input() contactId: number;
  @Input() borrowerId: number;
  @Input({required: true}) type: 'commercial' | 'consumer';

  /**
   * Can be used for reference to the PDF of the latest credit pull
   * OR can be replaced by appending the file path of the PDF onto the
   * state (hooked onto creditReport$ Observable and corresponding selectors
   * from FinancesState)
   */
  creditReportDocument$: Observable<Document>;
  business$: Observable<Business | null> = this.store.select( BusinessState.business );
  canForceRePullCredit$: Observable<boolean> = this.store.select( AuthState.userHasPermission('lpxCanForceRePullCredit') );
  financesStateLoading$: Observable<boolean> = this.store.select( FinancesState.loading );

  @Select(SaasFeaturesState.saasPermitted('consumerHardCreditExperian')) hasExperianConsumerHardPullFeature$: Observable<boolean>;

  canPullCredit$: Observable<boolean>;
  creditReport$: Observable<ConsumerCreditReport>;

  destroyed$ = new Subject<boolean>();
  creditRangeIndex$: Observable<number>;

  /**
   * Used to map credit score to ranges for display logic
   */
  creditScoresRangeIncrements = [
    {
      label: 'Poor',
      low: 300,
      high: 579,
      colorClass: 'bg-lendio-red-400'
    },
    {
      label: 'Fair',
      low: 580,
      high: 669,
      colorClass: 'bg-lendio-orange-400',
    },
    {
      label: 'Good',
      low: 670,
      high: 739,
      colorClass: 'bg-lendio-yellow-400'
    },
    {
      label: 'Very Good',
      low: 740,
      high: 799,
      colorClass: 'bg-lendio-lime-400'
    },
    {
      label: 'Exceptional',
      low: 800,
      high: 850,
      colorClass: 'bg-lendio-green-400'
    },
  ];

  constructor(
    private dialog: MatDialog,
    private store: Store,
    private doc: DocumentsService
  ) {}

  ngOnInit():void {}

  viewReport() {
    this.creditReportDocument$.pipe(
      take(1),
      filter(doc => !!doc?.id),
    ).subscribe(doc => {
      const url = `${environment.apiUrl}/document/${doc.id}?stream=1`;
      this.doc.openInNewTab(url, doc.id);
    });
  }

  /**
   * component works for commercial (business-centric) or consumer (by contact ID)
   * the creditReport$ selector and pull credit method should perform accordingly
   */
  ngOnChanges(): void {
    this.initCreditSelector();
    this.creditRangeIndex$ = this.creditReport$.pipe(
      takeUntil(this.destroyed$),
      filter( creditReport => !!creditReport?.creditScore ),
      map( creditReport => {
        return this.getCreditRangeIndex(creditReport.creditScore);
      })
    );
  }

  /**
   * Adjust creditReport$ observable for either consumer or commercial
   */
  initCreditSelector(): void {
    this.creditReport$ = this.type === 'consumer'
      ? this.store.select( FinancesState.creditReportByContactId(this.contactId) )
      : this.store.select( FinancesState.commercialCredit );

    this.creditReportDocument$ = this.type === 'consumer'
      ? this.store.select( DocumentsState.consumerCreditReportDocByContactId(this.contactId) )
      : this.store.select( DocumentsState.commercialCreditReportDoc );

    this.canPullCredit$ = combineLatest([this.creditReport$, this.financesStateLoading$, this.canForceRePullCredit$])
      .pipe(
        takeUntil(this.destroyed$),
        map(([creditReport, loading, canForceRePullCredit]) => {
          // more than {daysBeforeCreditPullIsStale} days have elapsed since current creditReport reportDate, or user has permission to override
          const meetsRepullThreshhold = !creditReport?.reportDate || isAfter(new Date(Date.now()), addDays(new Date(creditReport.reportDate), creditReport.daysBeforeCreditPullIsStale as number))
          return !loading && (canForceRePullCredit || meetsRepullThreshhold);
        }),
      );
  }

  /**
   * Show confirmation dialog, pull credit on confirm (from EventEmitter on ConfirmDialog component)
   */
  doPullCredit() {
    const dialogRef = this.dialog.open( ConfirmDialogComponent, {
      data: {
        title: 'Are you sure you want to pull credit?',
        description: 'Hard credit inquiries usually show on credit reports and impact credit scores.',
        confirmLabel: 'Pull credit'
      }
    });

    dialogRef.componentInstance.onCancel.pipe(
      take(1),
    ).subscribe( () => dialogRef.close() );
    dialogRef.componentInstance.onConfirm.pipe(
      take(1),
    ).subscribe( () => {
      dialogRef.close();
      this.type === 'commercial'
        ? this.pullCommercialCredit()
        : this.pullOwnerCredit();
    });
  }

  /**
   * For a single owner (by contact id) associated with the given business
   */
  pullOwnerCredit() {
    this.store.dispatch( new GetOwnerCreditReport(this.contactId) );
  }

  /**
   * The commercial credit report for the given business, not specific to any single owner or contact
   */
  pullCommercialCredit() {
    this.business$.pipe(
      take(1),
    ).subscribe( business => {
      !!business?.id ? this.store.dispatch( new GetCommercialCreditReport(business.id) ) : false;
    });
  }

  /**
   * Maps a credit score (between 300 and 850) to an index
   * between 0-4 corresponding to the colored and labeled range display
   * in the component template (uses the index to set the grid column of the display
   * indicator arrow)
   * @param score
   */
  getCreditRangeIndex(score) {
    return score <= 300
      ? 1
      : score >= 800
        ? 5
        : this.creditScoresRangeIncrements.reduce( (res, cur, i) => {
          return score >= cur.low && score <= cur.high
            ? i + 1
            : score > 850
              // either very exceptional or extremely poor
              ? 4 : res;
        }, 0);
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
