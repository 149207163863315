import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { DealNote } from '@app/app/interfaces/deal-note.model';
import { Store } from '@ngxs/store';
import { DealNotesState } from '@app/app/store/deal-notes/deal-notes.state';
import { AddDealNote } from '@app/app/store/deal-notes/deal-notes.actions';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CreateNoteDialogComponent } from '@app/app/components/deal-notes/create-note-dialog/create-note-dialog.component';
import { take } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { EmptyStateComponent } from '../empty-state/empty-state.component';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    EmptyStateComponent,
    MatButtonModule,
    MatCardModule
  ],
  selector: 'app-deal-notes-tab',
  templateUrl: './deal-notes-tab.component.html',
  styleUrls: ['./deal-notes-tab.component.scss'],
})
export class DealNotesTabComponent implements OnInit {
  notes$: Observable<DealNote[]> = this._store.select(DealNotesState.dealNotes);
  @Input({ required: true }) dealId: number;

  constructor(public dialog: MatDialog, private _store: Store) {}

  ngOnInit(): void {}

  openAddNote() {
    const dialogRef: MatDialogRef<CreateNoteDialogComponent> = this.dialog.open(
      CreateNoteDialogComponent,
      {
        width: '450px',
      }
    );

    dialogRef.componentInstance.saveNote
      .pipe(take(1))
      .subscribe((noteContent) => {
        if (noteContent?.length) {
          this._store.dispatch(new AddDealNote(this.dealId, noteContent));
        }

        dialogRef.close();
      });
  }
}
